import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { Router } from "@frui.ts/screens";
import AdvanceListItemState from "entities/advanceListItemState";
import AdvancesRepository from "repositories/advancesRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import AdvanceChangeViewModel from "viewModels/finance/advanceChangeViewModel";
import AdvanceDetailViewModel from "viewModels/finance/advanceDetailViewModel";
import AdvancesPageViewModel from "viewModels/finance/advancesPageViewModel";
import { IDetailPage } from "viewModels/types";
import ProductDetailViewModel from "../productDetailViewModel";

const navigationName = "advances";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class AdvancesDetailPageViewModel extends AdvancesPageViewModel implements IDetailPage {
  orderIndex = 1;
  navigationName = navigationName;
  className = "icon-receipt";

  parent: ProductDetailViewModel;

  get supplyPoint() {
    return this.parent.productDetailContext.supplyPoint;
  }

  get supplyPointId() {
    return this.parent.productDetailContext.supplyPointId;
  }

  get partnerId() {
    return this.parent.productDetailContext.partnerId;
  }

  constructor(
    localization: ILocalizationService,
    userContext: UserContext,
    dataService: DataSyncService,
    repository: AdvancesRepository,
    enumsService: EnumsService,
    router: Router,
    detailFactory: ReturnType<typeof AdvanceDetailViewModel.Factory>,
    advanceChangeFactory: ReturnType<typeof AdvanceChangeViewModel.Factory>
  ) {
    super(localization, userContext, dataService, repository, enumsService, router, detailFactory, advanceChangeFactory);

    this.name = this.translateProductScope("title");
  }

  @bound
  onInitialize() {
    this.filter.supplyPointIds = [this.supplyPointId];
    this.initializeStateFilter();
  }

  @bound
  navigate(subPath: string | undefined, params: { filter?: AdvanceListItemState; change?: boolean }): Promise<void> {
    if (params.change) {
      return this.activateAdvanceChange(this.partnerId, this.supplyPointId);
    }
    return super.navigate(subPath, { ...params, disablePastAdvancesOnly: true });
  }

  get depositDueAmount() {
    return this.parent.productDetailContext.supplyPoint?.depositDueAmount;
  }

  get suffix() {
    if (this.depositDueAmount && this.depositDueAmount < 0) {
      return this.localization.formatNumber(this.depositDueAmount) + " " + this.translateGeneral("currency");
    }
    return undefined;
  }

  @bound translateProductScope(key: string) {
    return this.localization.translateGeneral(`products.advances.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
