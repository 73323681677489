import { Button, Card, Col, LoaderOverlay, Row, SwitchNav } from "@emanprague/ppaskit";
import { Check, Input } from "@frui.ts/bootstrap";
import { ResponsiveTable } from "@frui.ts/dataviews";
import { preventDefault, registerView, View, ViewComponent } from "@frui.ts/views";
import EmptyDataMessage from "components/EmptyDataMessage";
import { getColoredLabelFinance } from "helpers/labelHelper";
import FilterButton from "controls/filterButton";
import { createColumnsContext, DataColumn } from "controls/tableHelper";
import DatePicker from "controls/twoway/DatePicker";
import RangeInput from "controls/twoway/RangeInput";
import AdvanceListItem from "entities/advanceListItem";
import { getIconByStateType } from "helpers/financeHelper";
import { observer } from "mobx-react-lite";
import React from "react";
import AdvancesPageViewModel, { defaultAmountRange } from "viewModels/finance/advancesPageViewModel";
import AdvancesDetailPageViewModel from "viewModels/products/myProducts/detailPages/advancesDetailPageViewModel";
import ModalAdvancePaymentMethodViewModel from "viewModels/products/myProducts/detailPages/optionsDeposit/modalAdvancePaymentMethodViewModel";

const columns: DataColumn<AdvanceListItem, AdvancesPageViewModel>[] = [
  {
    property: "period",
    titleFactory: ({ ta }) => ta("attributes", "period"),
    valueFormatter: valueProps => getColoredLabelFinance(valueProps),
  },
  {
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("attributes", "frequency")}
        <FilterButton
          id="advanceFrequencyFilter"
          isActive={!!vm.filter.advanceFrequencyId}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("advanceFrequencyId")}
          onConfirm={vm.applyFilterAndLoad}>
          {vm.enumsService.getValues("advanceFrequencies").map((item, index) => (
            <Check
              custom
              key={item.id}
              value={item.id}
              target={vm.filter}
              property="advanceFrequencyId"
              label={item.name}
              className="mt-1"
              type="radio"
            />
          ))}
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ vm, ta }) => ta("attributes", "frequency"),
    valueFormatter: ({ item, context }) =>
      getColoredLabelFinance({ item }, context.vm.enumsService.getValue("advanceFrequencies", item.advanceFrequencyId)?.name),
  },
  {
    property: "vs",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("advance", "vs")}
        <FilterButton
          id="vsFilter"
          isActive={!!vm.filter.vs}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("vs")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="vs" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ vm, ta }) => ta("advance", "vs"),
    valueFormatter: valueProps => getColoredLabelFinance(valueProps),
  },
  {
    property: "dueDate",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("advance", "due_date")}
        <FilterButton
          id="dueDateFilter"
          isActive={!!vm.filter.dueDateFrom || !!vm.filter.dueDateTo}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("dueDateFrom", "dueDateTo")}
          onConfirm={vm.applyFilterAndLoad}>
          <Row className="align-items-center">
            <Col className="mr-n3">
              <span className="date-filter">{vm.translateGeneral("filter_date_from")}</span>
              <DatePicker
                target={vm.filter}
                property="dueDateFrom"
                selectsStart
                startDate={vm.filter.dueDateFrom}
                endDate={vm.filter.dueDateTo}
                maxDate={vm.filter.dueDateTo}
              />
            </Col>
            <Col>
              <span className="date-filter to">{vm.translateGeneral("filter_date_to")}</span>
              <DatePicker
                target={vm.filter}
                property="dueDateTo"
                selectsEnd
                startDate={vm.filter.dueDateFrom}
                endDate={vm.filter.dueDateTo}
                minDate={vm.filter.dueDateFrom}
              />
            </Col>
          </Row>
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ vm, ta }) => ta("advance", "due_date"),
    valueFormatter: valueProps =>
      getColoredLabelFinance(valueProps, valueProps.context.vm.localization.formatDate(valueProps.value)),
  },
  {
    property: "amount",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("advance", "amount")}
        <FilterButton
          id="amountFilter"
          isActive={!!vm.filter.minAmount || !!vm.filter.maxAmount}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("minAmount", "maxAmount")}
          onConfirm={vm.applyFilterAndLoad}>
          <RangeInput
            target={vm}
            property="amountRange"
            allowUserOverride={true}
            min={defaultAmountRange[0]}
            max={defaultAmountRange[1]}
            step={100}
            disabled={false}
            autofocus
            suffix={" " + vm.translateGeneral("currency")}
            name="rangeslider"
            form="rangeform"
          />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ vm, ta }) => ta("advance", "amount"),
    valueFormatter: valueProps => (
      <span className="text-nowrap">
        {getColoredLabelFinance(
          valueProps,
          valueProps.context.vm.localization.formatNumber(valueProps.value) +
            " " +
            valueProps.context.vm.translateGeneral("currency")
        )}
      </span>
    ),
    cellClassName: "cell-right",
    headerClassName: "cell-right",
  },
  {
    property: "stateText",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("advance", "state")}
        <FilterButton
          id="stateFilter"
          isActive={!!vm.filter.state}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={vm.initializeStateFilter}
          onConfirm={vm.applyStateFilter}>
          {vm.stateTypes.map(item => (
            <Check
              custom
              key={item.id}
              value={item.id}
              target={vm}
              property="selectedState"
              label={item.name}
              className="mt-1"
              type="radio"
            />
          ))}
        </FilterButton>
      </>
    ),
    valueFormatter: valueProps => (
      <span className="text-nowrap">
        <img className="mr-2" width="22" height="22" draggable={false} src={getIconByStateType(valueProps.item.state)} alt="" />
        {getColoredLabelFinance(valueProps)}
      </span>
    ),
  },
  {
    valueFormatter: ({ item, context }) => (
      <>
        {item.allowToPay ? (
          <Button onClick={() => context.vm.openDetail(item)} size="sm" className="btn-responsive-block">
            {context.vm.translateGeneral("pay_button")}
          </Button>
        ) : (
          <Button onClick={() => context.vm.openDetail(item)} size="sm" className="btn-responsive-block" variant="secondary">
            {context.vm.translateGeneral("button_more")}
          </Button>
        )}
      </>
    ),
    responsiveTitle: false,
    cellClassName: "cell-right",
  },
];

const AdvancesPageListView: ViewComponent<AdvancesPageViewModel> = observer(({ vm }) => {
  const navLinks = [
    {
      id: "all",
      title: `${vm.translateGeneral("filter_all")}`,
      href: "#",
      onClick: preventDefault(() => vm.clearFilter("state")),
    },
    {
      id: "paid",
      title: `${vm.translate("filter_paid")}`,
      href: "#",
      onClick: preventDefault(() => vm.applySwitchStateFilter("paid", "payment_in_process")),
    },
    {
      id: "unpaid",
      title: `${vm.translate("filter_unpaid")}`,
      href: "#",
      onClick: preventDefault(() => vm.applySwitchStateFilter("unpaid", "before_payment")),
    },
  ];

  return (
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Row className="mb-3 justify-content-between align-items-center">
        <Col xs={12} md="auto" className="py-2">
          <SwitchNav links={navLinks} size="sm" activeId={vm.switchStateActiveId} />
        </Col>
        <Col />
        <Col xs={12} md={5} className="py-2">
          <Input
            size="sm"
            target={vm.filter}
            property="search"
            placeholder={vm.translate("search_placeholder")}
            onValueChanged={vm.applyFilterAndLoadDebounced}
          />
        </Col>
      </Row>
      <div className="responsive-table-wrapper">
        <ResponsiveTable
          className="table no-wrap"
          items={vm.items}
          itemKey="id"
          columns={columns}
          context={createColumnsContext(vm)}
        />
      </div>
      {!vm.items?.length && <EmptyDataMessage translateGeneral={vm.localization.translateGeneral} />}

      {vm.canLoadData && (
        <div className="load-more-button">
          <Button onClick={vm.loadData} variant="secondary">
            {vm.translateGeneral("button_load_more")}
          </Button>
        </div>
      )}

      <View vm={vm.activeChild} />
    </LoaderOverlay>
  );
});

const advancesDetailListView: ViewComponent<AdvancesDetailPageViewModel> = observer(({ vm }) => (
  <div className="mb-4">
    <Row className="justify-content-between align-items-center">
      <Col xs={12} sm="auto" className="mb-3">
        <h2 className="display-2 mb-0">{vm.translateProductScope("title")}</h2>
      </Col>
      <Col xs={12} sm="auto" className="mb-3">
        <Button
          variant="primary"
          className="mr-2"
          href={vm.router.getUrl(ModalAdvancePaymentMethodViewModel, { supplyPointId: vm.supplyPointId })}>
          {vm.translateProductScope("button_advances_pay")}
        </Button>
        <Button className="ml-auto" variant="primary" onClick={() => vm.activateAdvanceChange(vm.partnerId, vm.supplyPointId)}>
          {vm.translateProductScope("button_advances_high")}
        </Button>
      </Col>
    </Row>

    <Card>
      <Card.Body>
        <AdvancesPageListView vm={vm} />
      </Card.Body>
    </Card>
  </div>
));

registerView(advancesDetailListView, AdvancesDetailPageViewModel);
