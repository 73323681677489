import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import SupplyPointDetailPageViewModel from "viewModels/products/myProducts/detailPages/supplyPoint/supplyPointDetailPageViewModel";
import { AccountStatus, Button, Card, Col, Graph, HeaderRow, LinkList, LoaderOverlay, Row } from "@emanprague/ppaskit";
import "../style.scss";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit.svg";
import InvoicesDetailPageViewModel from "viewModels/products/myProducts/detailPages/invoicesDetailPageViewModel";
import AdvancesDetailPageViewModel from "viewModels/products/myProducts/detailPages/advancesDetailPageViewModel";
import { getCommodityUnit } from "helpers/commodityHelper";
import CommodityType from "models/commodityType";

const supplyPointDetailListView: ViewComponent<SupplyPointDetailPageViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;
  const { translateModel: tm } = vm.localization;

  return (
    <div className="mb-4">
      {vm.activeChild?.navigationName !== "transferCustomer" && (
        <>
          <Row className="justify-content-between align-items-center">
            <Col xs={12} sm="auto" className="mb-3">
              <h2 className="display-2 mb-0">{vm.translate("title")}</h2>
            </Col>
            {process.env.REACT_APP_DISABLE_TRANSFER !== "true" && vm.supplyPointContract?.active && (
              <Col xs={12} sm="auto" className="mb-3">
                <Button className="ml-auto" variant="primary" onClick={vm.activateCustomerTransfer}>
                  {vm.translate("button_customer_change")}
                </Button>
              </Col>
            )}
          </Row>
          <LoaderOverlay loading={vm.busyWatcher.isBusy}>
            <Row noGutters>
              <Col xs={12} md={6} className="pr-sm-3 pb-3">
                <AccountStatus status={vm.dueItemsSummary === 0} title={vm.accountStatus} className="mx-n3 ml-sm-0 mx-md-0 mb-3">
                  <Row>
                    <Col>
                      {vm.dueItemsSummary === 0 ? (
                        <div className="mb-4 mx-auto">
                          {vm.translateGeneral("balance_widget.ok_detail_part1")}
                          <br />
                          {vm.translateGeneral("balance_widget.ok_detail_part2")}
                        </div>
                      ) : (
                        <span className="font-size-sm">
                          {vm.translateGeneral("balance_summary")}:{" "}
                          <strong className="text-nowrap">
                            {vm.localization.formatNumber(vm.balanceSummary)} {vm.translateGeneral("currency")}
                          </strong>{" "}
                          (+&nbsp;{vm.translateGeneral("balance_summary_fees")})
                        </span>
                      )}
                    </Col>
                  </Row>
                  {vm.isOneOrMoreDue && (
                    <Row className="my-4 justify-content-center">
                      {vm.supplyPoint && vm.supplyPoint.depositDueCount > 0 && (
                        <Col xs="auto">
                          <a
                            className="text-indigo font-size-sm text-nowrap"
                            href={vm.router.getUrl(
                              AdvancesDetailPageViewModel,
                              { supplyPointId: vm.supplyPoint.id },
                              { filter: "unpaid" }
                            )}>
                            {vm.translateGeneral("overdue_advances")}
                          </a>
                        </Col>
                      )}
                      {vm.supplyPoint && vm.supplyPoint.invoiceDueCount > 0 && (
                        <Col xs="auto">
                          <a
                            className="text-indigo font-size-sm text-nowrap"
                            href={vm.router.getUrl(
                              InvoicesDetailPageViewModel,
                              { supplyPointId: vm.supplyPoint.id },
                              { filter: "unpaid" }
                            )}>
                            {vm.translateGeneral("overdue_invoices")}
                          </a>
                        </Col>
                      )}
                    </Row>
                  )}
                </AccountStatus>
                <Card className="mx-n3 ml-sm-0 mx-md-0">
                  <Card.Body>
                    <h4 className="display-4 mb-5">
                      {vm.translate("history_consumption")} ({getCommodityUnit(vm.commodityType)})
                    </h4>
                    <Graph labels={vm.chartLabels} datasets={vm.chartDatasets} />
                  </Card.Body>
                </Card>
                <LinkList
                  title={vm.translate("suggested_links.title")}
                  className="mx-n3 ml-sm-0 mx-md-0 mt-3"
                  links={vm.suggestedLinks.map((item, index) => (
                    <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                      {item.text}
                    </a>
                  ))}
                />
              </Col>
              <Col xs={12} md={6}>
                <Card className="mx-n3 mx-sm-0 mb-3">
                  <Card.Body>
                    {vm.supplyPoint && (
                      <>
                        <HeaderRow level={4} title={vm.translate("detail_data")}>
                          <Button className="ml-auto" variant="secondary" onClick={vm.activateEditSupplyPointModal}>
                            <img src={iconEdit} width={16} height={16} alt="" className="mr-1 ml-n1 my-n1" />{" "}
                            {vm.translate("button_edit")}
                          </Button>
                        </HeaderRow>
                        <table className="table aligned wider-row mb-5">
                          <tbody>
                            <tr>
                              <th className="border-top-0">{ta("supply_point", "name")}</th>
                              <td className="border-top-0">{vm.supplyPoint.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("supply_point", "address_text")}</th>
                              <td>{vm.supplyPoint.addressText}</td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("supply_point", "floor")}</th>
                              <td>{vm.installationFloorName}</td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("supply_point", "flat_number")}</th>
                              <td>{vm.supplyPoint.flatNumber}</td>
                            </tr>
                            <tr>
                              <th scope="row">{tm("contract", 1)}</th>
                              <td>
                                {vm.supplyPointContract?.active ? vm.translateGeneral("active") : vm.translateGeneral("inactive")}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("contract", "number")}</th>
                              <td>{vm.supplyPointContract?.code}</td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("account", "number")}</th>
                              <td>{vm.supplyPoint.account.externalId}</td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("contract", "commodity")}</th>
                              <td>{vm.commodityName}</td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("contract", "product")}</th>
                              <td>{vm.supplyPointContract?.product}</td>
                            </tr>
                          </tbody>
                        </table>
                        <HeaderRow level={4} title={vm.translate("technical_data")} />
                        <table className="table aligned wider-row">
                          <tbody>
                            <tr>
                              <th className="border-top-0">{ta("supply_point", "inst_id")}</th>
                              <td className="border-top-0">{vm.supplyPoint.instId}</td>
                            </tr>
                            <tr>
                              <th scope="row">
                                {vm.commodityType === CommodityType.Electricity
                                  ? ta("supply_point", "ean")
                                  : ta("supply_point", "eic")}
                              </th>
                              <td>{vm.supplyPoint.code}</td>
                            </tr>
                            <tr>
                              <th scope="row">
                                {vm.commodityType === CommodityType.Gas
                                  ? ta("supply_point", "device_id_gas")
                                  : ta("supply_point", "device_id_electricity")}
                              </th>
                              <td>{vm.supplyPoint.deviceId}</td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("supply_point", "usage")}</th>
                              <td>{vm.supplyPoint.usage}</td>
                            </tr>
                            <tr>
                              <th scope="row">{ta("supply_point", "distributor")}</th>
                              <td>{vm.supplyPoint.distributor}</td>
                            </tr>
                            {vm.commodityType === CommodityType.Gas && (
                              <tr>
                                <th scope="row">{ta("supply_point", "appliances")}</th>
                                <td>{vm.supplyPoint.appliances}</td>
                              </tr>
                            )}
                            {vm.commodityType === CommodityType.Electricity && (
                              <tr>
                                <th scope="row">{ta("supply_point", "tariff")}</th>
                                <td>{vm.supplyPoint.tariff}</td>
                              </tr>
                            )}
                            <tr>
                              <th scope="row">{ta("supply_point", "reading_month")}</th>
                              <td>{vm.supplyPoint.readingPeriod}</td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </LoaderOverlay>
        </>
      )}
      <Observer>{() => <View vm={vm.activeChild} />}</Observer>
    </div>
  );
});

registerView(supplyPointDetailListView, SupplyPointDetailPageViewModel);
