/* eslint-disable @typescript-eslint/no-throw-literal */
import { EnumsServiceBase, ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import PortalSettings from "entities/portalSettings";
import SimplePortalSettings from "entities/simplePortalSettings";
import PortalSettingsRepository from "repositories/portalSettingsRepository";

export default class PortalSettingsService extends EnumsServiceBase<PortalSettings> {
  constructor(
    private repository: PortalSettingsRepository,
    private notificationService: INotificationService,
    private localization: ILocalizationService
  ) {
    super();
  }

  getSimplePortalSettings(key: string): SimplePortalSettings {
    const simpleSettings = this.getValues("simple");
    const result = simpleSettings.find((setting: SimplePortalSettings) => setting.key === key);
    if (!result) {
      throw new Error(`PortalSetting container does not contain settings for ${key}`);
    }

    return result;
  }

  async updatePortalSettings(setting: SimplePortalSettings) {
    const response = await this.repository.updatePortalSettings(setting);
    if (response.success) {
      this.notificationService.addNotification(
        this.localization.translateGeneral("general.setting_saved"),
        SeverityLevel.success
      );
    } else {
      this.notificationService.addNotification(
        this.localization.translateGeneral("general.setting_failed"),
        SeverityLevel.critical
      );
    }
  }

  protected async loadContainer() {
    const data = await this.repository.getPortalSettings();
    if (data.success) {
      return data.payload;
    }

    throw data.payload;
  }
}
