import { Button, Card, Col, ProductRow, Row } from "@emanprague/ppaskit";
import iconIndicatorGreen from "@emanprague/ppaskit/dist/assets/icons/Indicator Green.svg";
import iconIndicatorBlue from "@emanprague/ppaskit/dist/assets/icons/Indicator Blue.svg";
// import iconPlus from "@emanprague/ppaskit/dist/assets/icons/Plus.svg";
import iconStateNotOk from "@emanprague/ppaskit/dist/assets/icons/State/Not Ok.svg";
import iconStateOk from "@emanprague/ppaskit/dist/assets/icons/State/Ok.svg";
import { ILocalizationService } from "@emanprague/shared-services";
import { Router } from "@frui.ts/screens";
import { observer } from "mobx-react-lite";
import CommodityType from "models/commodityType";
import React from "react";
import AdvancesDetailPageViewModel from "viewModels/products/myProducts/detailPages/advancesDetailPageViewModel";
import ModalSelfReadingViewModel from "viewModels/products/myProducts/detailPages/history/modalSelfReadingViewModel";
import InvoicesDetailPageViewModel from "viewModels/products/myProducts/detailPages/invoicesDetailPageViewModel";
import CustomerTransferViewModel from "viewModels/products/myProducts/detailPages/supplyPoint/customerTransferViewModel";
import ProductDetailViewModel from "viewModels/products/myProducts/productDetailViewModel";
import { getSupplyPointTypeIcon } from "helpers/supplyPointHelper";
import iconArrowRightGray from "@emanprague/ppaskit/dist/assets/icons/Arrow/Right.svg";
import OverviewViewModel from "viewModels/supplyPoints/overviewViewModel";
import SupplyPointListItem from "entities/supplyPointListItem";
import PaymentsDetailPageViewModel from "viewModels/products/myProducts/detailPages/paymentsDetailPageViewModel";
// import { openInNewTab } from "helpers/utils";

interface SupplyPointRowProps {
  supplyPoint: SupplyPointListItem;
  localization: ILocalizationService;
  router: Router;
}

const SupplyPointRow: React.FunctionComponent<SupplyPointRowProps> = observer(({ supplyPoint, localization, router }) => {
  const { translateGeneral: tg, translateModel: tm } = localization;
  const translate = (code: string) => tg(`dashboard.supply_points.${code}`);
  const translateGeneral = (code: string) => tg(`general.${code}`);

  const addressText = supplyPoint.addressText.slice(0, supplyPoint.addressText.length - 7);
  const addressZip = supplyPoint.addressText.slice(supplyPoint.addressText.length - 7);

  return (
    <div className="px-sm-2">
      <Card key={supplyPoint.id} className="mb-3 px-2 px-sm-0">
        <Card.Body className="py-4">
          <Row className="align-items-center ">
            <Col xs={12} md={10} lg={5} className="mr-auto mb-md-2 mb-lg-0">
              <h4 className="display-4 mb-0">
                {supplyPoint.name ?? (
                  <>
                    {addressText} <span style={{ whiteSpace: "nowrap" }}>{addressZip}</span>
                  </>
                )}
              </h4>
              <div className="text-muted mb-2">
                {addressText} <span style={{ whiteSpace: "nowrap" }}>{addressZip}</span>
              </div>

              <div className="py-sm-2">
                {supplyPoint.contract.active ? (
                  <span className="text-success d-block mt-2 mb-md-2 mb-lg-0 font-size-sm">
                    <img className="pr-2 pb-1" src={iconIndicatorGreen} alt="" />
                    {`${translate("contract_number")} ${supplyPoint.contract.code} ${translate("contract_is")} `}
                    {translateGeneral("active").toLowerCase()}
                  </span>
                ) : (
                  <span className="text-info d-block mt-2 mb-md-2 mb-lg-0 font-size-sm">
                    <img className="pr-2 pb-1" src={iconIndicatorBlue} alt="" />
                    {`${translate("contract_number")} ${supplyPoint.contract.code} ${translate("contract_is")} `}
                    {translateGeneral("inactive").toLowerCase()}
                  </span>
                )}
              </div>
            </Col>
            <Col xs={12} md="auto" className="my-4 mt-md-0 mb-3 mb-lg-0 mt-lg-1">
              {supplyPoint.dashboardNotice && (
                // Not the best way. Ass soon as the notice gets used to anything else or disabled, this will need to be changed.
                <>
                  <img className="pr-2" width={26} height={26} src={iconStateOk} alt="" />
                  <a
                    href={router.getUrl(PaymentsDetailPageViewModel, { supplyPointId: supplyPoint.id })}
                    className="color-blue-dark font-size-sm">
                    {tm("subsidy", 2)}
                  </a>
                </>
              )}

              <img
                className="ml-4 pr-2"
                width={26}
                height={26}
                src={supplyPoint.depositDueCount === 0 ? iconStateOk : iconStateNotOk}
                alt=""
              />
              <a
                href={router.getUrl(AdvancesDetailPageViewModel, { supplyPointId: supplyPoint.id })}
                className="color-blue-dark font-size-sm">
                {tm("advance", 2)}
              </a>

              <img
                className="ml-4 pr-2"
                width={26}
                height={26}
                src={supplyPoint.invoiceDueCount === 0 ? iconStateOk : iconStateNotOk}
                alt=""
              />
              <a
                href={router.getUrl(InvoicesDetailPageViewModel, { supplyPointId: supplyPoint.id })}
                className="color-blue-dark font-size-sm">
                {tm("invoice", 2)}
              </a>
            </Col>
            <Col xs={12} lg="auto" className="ml-lg-auto ml-xl-3">
              <Row noGutters className="text-nowrap">
                <Col xs={6} md="auto" className="pr-1">
                  <Button
                    variant="secondary"
                    className="my-1 mb-lg-0 w-100"
                    href={router.getUrl(AdvancesDetailPageViewModel, { supplyPointId: supplyPoint.id }, { change: true })}>
                    {translate("deposit_change")}
                  </Button>
                </Col>
                <Col xs={6} md="auto" className="pl-1 pr-md-1">
                  <Button
                    variant="secondary"
                    className="my-1 mb-lg-0 w-100"
                    href={router.getUrl(ModalSelfReadingViewModel, { supplyPointId: supplyPoint.id })}>
                    {translate("button_self_reading")}
                  </Button>
                </Col>
                {process.env.REACT_APP_DISABLE_TRANSFER !== "true" && (
                  <Col xs={6} md="auto" className="pr-1 pl-md-1">
                    <Button
                      variant="secondary"
                      className="my-1 mb-lg-0 w-100"
                      href={router.getUrl(CustomerTransferViewModel, { supplyPointId: supplyPoint.id })}>
                      {translate("customer_transfer")}
                    </Button>
                  </Col>
                )}
                <Col xs={6} md="auto" className="pl-1">
                  <Button
                    variant="primary"
                    className="my-1 mb-lg-0 w-100"
                    href={router.getUrl(ProductDetailViewModel, { supplyPointId: supplyPoint.id })}>
                    {translate("detail")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
});

interface SupplyPointsListProps {
  localization: ILocalizationService;
  supplyPointsByType: Map<CommodityType, SupplyPointListItem[]>;
  router: Router;
  newSupplyPointLink: string;
  limitOfDisplayedSupplyPoints?: number;
}

// TODO: Duplicate <ProductRow, maybe to unify in 1 component?
const SupplyPointsList: React.FunctionComponent<SupplyPointsListProps> = observer(
  ({ localization, supplyPointsByType, router, newSupplyPointLink, limitOfDisplayedSupplyPoints }) => {
    const { translateGeneral: tg, translateModel: tm } = localization;
    const translate = (code: string) => tg(`dashboard.supply_points.${code}`);
    const translateGeneral = (code: string) => tg(`general.${code}`);
    // const addNewSupplyPoint = () => openInNewTab(newSupplyPointLink);

    let gasSupplyPoints = supplyPointsByType.get(CommodityType.Gas);
    if (gasSupplyPoints) {
      gasSupplyPoints = [
        ...gasSupplyPoints.filter(item => item.contract.active),
        ...gasSupplyPoints.filter(item => !item.contract.active),
      ];
    }

    let electricitySupplyPoints = supplyPointsByType.get(CommodityType.Electricity);
    if (electricitySupplyPoints) {
      electricitySupplyPoints = [
        ...electricitySupplyPoints.filter(item => item.contract.active),
        ...electricitySupplyPoints.filter(item => !item.contract.active),
      ];
    }

    if (limitOfDisplayedSupplyPoints) {
      gasSupplyPoints = gasSupplyPoints?.slice(0, limitOfDisplayedSupplyPoints);
      electricitySupplyPoints = electricitySupplyPoints?.slice(0, limitOfDisplayedSupplyPoints);
    }

    const electricityDisplayAllButton = (className: string) => (
      <>
        {limitOfDisplayedSupplyPoints &&
          supplyPointsByType.get(CommodityType.Electricity)!.length > limitOfDisplayedSupplyPoints && (
            <Button
              variant="link"
              className={"font-size-base font-weight-bold link-underline-hover " + className}
              href={router.getUrl(OverviewViewModel)}>
              <span className="d-flex align-items-center">
                {translateGeneral("display_all_button")}
                <img className="ml-2 opacity-third" width="15" height="12" draggable={false} src={iconArrowRightGray} alt="" />
              </span>
            </Button>
          )}
      </>
    );

    const gasDisplayAllButton = (className: string) => (
      <>
        {limitOfDisplayedSupplyPoints && supplyPointsByType.get(CommodityType.Gas)!.length > limitOfDisplayedSupplyPoints && (
          <Button
            variant="link"
            className={"font-size-base font-weight-bold link-underline-hover " + className}
            href={router.getUrl(OverviewViewModel)}>
            {translateGeneral("display_all_button")}
            <img className="ml-2 opacity-third" width="15" height="12" draggable={false} src={iconArrowRightGray} alt="" />
          </Button>
        )}
      </>
    );

    return (
      <>
        {gasSupplyPoints && (
          <div className="pb-4">
            <ProductRow
              id="pr-1"
              icon={getSupplyPointTypeIcon(CommodityType.Gas, "yellowAndLightBlue")}
              title={translate("gas")}
              titleSuffix={tm("supply_point", 3)}
              titleDisabled={!supplyPointsByType.get(CommodityType.Gas)}
              className="my-4 mt-sm-5"
              actions={
                <div className="d-flex align-items-center">
                  {/* <Button variant="light" onClick={addNewSupplyPoint}>
                    <img className="mr-md-3" width="14" height="14" src={iconPlus} alt="" />
                    <span className="d-none d-md-inline">{translateGeneral("new_place_button")}</span>
                  </Button> */}
                  {gasDisplayAllButton("pr-0 d-none d-sm-block")}
                </div>
              }
            />
            {gasSupplyPoints.map(supplyPoint => (
              <SupplyPointRow key={supplyPoint.id} supplyPoint={supplyPoint} localization={localization} router={router} />
            ))}
            {gasDisplayAllButton("d-flex justify-content-center d-sm-none")}
          </div>
        )}
        {electricitySupplyPoints && (
          <div className="pb-4">
            <ProductRow
              id="pr-1"
              icon={getSupplyPointTypeIcon(CommodityType.Electricity, "yellowAndLightBlue")}
              title={translate("electricity")}
              titleSuffix={tm("supply_point", 3)}
              titleDisabled={!supplyPointsByType.get(CommodityType.Electricity)}
              className="my-4 mt-sm-5"
              actions={
                <div className="d-flex align-items-center">
                  {/* <Button variant="light" onClick={addNewSupplyPoint}>
                    <img className="mr-md-3" width="14" height="14" src={iconPlus} alt="" />
                    <span className="d-none d-md-inline">{translateGeneral("new_place_button")}</span>
                  </Button> */}
                  {electricityDisplayAllButton("pr-0 d-none d-sm-block")}
                </div>
              }
            />
            {electricitySupplyPoints.map(supplyPoint => (
              <SupplyPointRow key={supplyPoint.id} supplyPoint={supplyPoint} localization={localization} router={router} />
            ))}
            {electricityDisplayAllButton("d-flex justify-content-center d-sm-none")}
          </div>
        )}
      </>
    );
  }
);

export default SupplyPointsList;
