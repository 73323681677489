import { registerView, View, ViewComponent } from "@frui.ts/views";
import { Observer, observer } from "mobx-react-lite";
import React from "react";
import OptionsDepositDetailViewModel from "viewModels/products/myProducts/detailPages/optionsDeposit/optionsDepositDetailPageViewModel";
import { Button, Card, Col, HeaderRow, Row } from "@emanprague/ppaskit";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit.svg";
import { addressToHuman } from "helpers/utils";

const optionsDepositDetailListView: ViewComponent<OptionsDepositDetailViewModel> = observer(({ vm }) => (
  <div className="mb-4">
    <HeaderRow level={2} title={vm.name} />

    <Card>
      {vm.account && (
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <HeaderRow level={3} title={vm.translate("invoice_mailing_method")}>
                <Button variant="secondary" size="sm" onClick={vm.activateInvoiceMailingMethodModal}>
                  <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                  {vm.translate("button_edit")}
                </Button>
              </HeaderRow>
              <div>
                {vm.account.invoiceSentMethod === "post" && (
                  <>
                    <strong className="font-size-sm">{vm.translate("by_post")}</strong>
                    <br />
                    <span className="font-size-sm">{addressToHuman(vm.addressByType("billing"))}</span>
                  </>
                )}
                {vm.account.invoiceSentMethod === "email" && (
                  <>
                    <strong className="font-size-sm">{vm.translate("by_email")}</strong>
                    <br />
                    <span className="font-size-sm">{vm.account.invoiceSentMethodEmail}</span>
                  </>
                )}
              </div>

              <HeaderRow level={3} title={vm.translate("invoice_payment_method")} className="mt-5">
                <Button variant="secondary" size="sm" onClick={vm.activateInvoicePaymentMethodModal}>
                  <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                  {vm.translate("button_edit")}
                </Button>
              </HeaderRow>
              <table className="table wider-row aligned mb-5 mb-md-0">
                <tbody>
                  <tr>
                    <th>{vm.translate("underpayments")}</th>
                    <td>{vm.translatePaymentMethod(vm.account.underpaymentMethodId)}</td>
                  </tr>
                  <tr>
                    <th>{vm.translate("overpayments")}</th>
                    <td>{vm.translatePaymentMethod(vm.account.overpaymentMethodId)}</td>
                  </tr>
                </tbody>
              </table>
            </Col>

            <Col xs={12} md={6}>
              <HeaderRow level={3} title={vm.translate("advance_payment_method")}>
                <Button variant="secondary" size="sm" onClick={vm.activateAdvancePaymentMethodModal}>
                  <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                  {vm.translate("button_edit")}
                </Button>
              </HeaderRow>
              <div className="font-size-sm">{vm.translatePaymentMethod(vm.account.advanceMethodId)}</div>
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
    <Observer>{() => <View vm={vm.activeChild} />}</Observer>
  </div>
));

registerView(optionsDepositDetailListView, OptionsDepositDetailViewModel);
