import { Button, Card, CardGroup, Col, Form, LoaderOverlay } from "@emanprague/ppaskit";
import { Input } from "@frui.ts/bootstrap";
import PasswordInput from "controls/twoway/PasswordInput";
import { Router } from "@frui.ts/screens";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import LoginViewModel from "viewModels/profile/loginViewModel";
import PreAuthHeader from "components/PreAuthHeader";
import Tooltip from "@emanprague/basekit/dist/Tooltip";

const loginListView: ViewComponent<LoginViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;
  const navigationLink = Router.getChildUrlFactory(vm.parent as any);

  return (
    <>
      <PreAuthHeader translateFunc={vm.localization.translateGeneral} />
      <CardGroup>
        <Card className="border-right-sm z-index-1">
          <LoaderOverlay loading={vm.busyWatcher.isBusy}>
            <Card.Body>
              <Form onSubmit={preventDefault(vm.login)}>
                <h1 className="display-1 text-center">{vm.translate("title")}</h1>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{ta("user", "email")}</Form.Label>
                    <Input target={vm.credentials} property="email" autoFocus />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{ta("user", "password")}</Form.Label>
                    <PasswordInput target={vm.credentials} property="password" feedbackInvalid={vm.errorMessage} />
                  </Form.Group>
                </Form.Row>

                <Button disabled={!vm.canLogin} type="submit" block={true}>
                  {vm.translate("login_button")}
                </Button>
                <Button
                  variant="link"
                  href={navigationLink("forgottenPassword")}
                  block={true}
                  className="font-weight-medium pb-sm-0">
                  {vm.translate("forgotten_password_button")}
                </Button>
              </Form>
            </Card.Body>
          </LoaderOverlay>
        </Card>
        <Card className="mb-0">
          <Card.Body className="text-center">
            <h1 className="display-1 font-weight-light">{vm.translate("no_account_yet")}</h1>
            <p className="pt-0 pb-2 pt-sm-4 pt-md-5 pb-sm-4 pb-md-5 mb-md-3 px-2 px-md-0 w-75 w-sm-100 font-size-md mx-auto">
              {vm.translate("no_account_yet_hint")}
            </p>
            <Button variant="secondary" href={navigationLink("registration")} className="mb-3 mb-md-2">
              {vm.translate("register_button")}
            </Button>
            <Tooltip
              id="accountrequirementstooltip"
              placement="top"
              tooltip={
                <div
                  className="font-size-base"
                  dangerouslySetInnerHTML={{ __html: vm.translate("account_requirements_tooltip") }}
                />
              }>
              <Button variant="link" className="text-nowrap p-0 pt-1 mt-2 font-weight-medium pb-sm-0 d-block mx-auto cursor-help">
                {vm.translate("account_requirements_link")}
              </Button>
            </Tooltip>
          </Card.Body>
        </Card>
      </CardGroup>
    </>
  );
});

registerView(loginListView, LoginViewModel);
