import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, computed, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import SupplyPointDetailPageViewModel from "./supplyPointDetailPageViewModel";
import ProductDetailContext from "models/productDetailContext";
import { interfaces } from "inversify";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import ReqChangeSupplyPointDetails from "entities/reqChangeSupplyPointDetails";
import ReqChangeSupplyPointAddress from "entities/reqChangeSupplyPointAddress";
import { unwrapErrorMessage } from "repositories/helpers";
import AddressChangeViewModel from "viewModels/addressChangeViewModel";
import EnumsService from "services/enumsService";

type StepName = "step1" | "step2";
type ChangeType = "details" | "address";

export default class ModalEditSupplyPointViewModel extends ScreenBase {
  parent: SupplyPointDetailPageViewModel;
  busyWatcher = new BusyWatcher();

  @observable successMessage?: string;
  @observable errorMessage?: string;
  @observable currentStep: StepName = "step1";
  @observable fileSelectError?: string;

  @observable supplyPointDetails: ReqChangeSupplyPointDetails;
  @observable supplyPointAddress: ReqChangeSupplyPointAddress;

  @observable addressChangeVM: AddressChangeViewModel;

  constructor(
    public productDetailContext: ProductDetailContext,
    public localization: ILocalizationService,
    private supplyPointsRepository: SupplyPointsRepository,
    private notificationService: INotificationService,
    private enumsService: EnumsService,
    addressChangeViewModelFactory: ReturnType<typeof AddressChangeViewModel.Factory>
  ) {
    super();

    this.name = this.translate("detail_data");
    this.supplyPointDetails = new ReqChangeSupplyPointDetails();
    this.supplyPointAddress = new ReqChangeSupplyPointAddress();
    attachAutomaticValidator(this.supplyPointDetails, ReqChangeSupplyPointDetails.ValidationRules);
    attachAutomaticValidator(this.supplyPointAddress, ReqChangeSupplyPointAddress.ValidationRules);

    const address = productDetailContext.supplyPoint?.address;
    if (!address) {
      throw new Error("Supply point detail is not loaded");
    }

    this.addressChangeVM = addressChangeViewModelFactory(
      address,
      this.supplyPointAddress,
      this.productDetailContext.supplyPoint,
      "supplyPoint"
    );

    const supplyPoint = this.productDetailContext?.supplyPoint;
    if (supplyPoint) {
      this.supplyPointDetails.name = supplyPoint.name;
      this.supplyPointDetails.flatNumber = supplyPoint.flatNumber;
      this.supplyPointDetails.installationFloorId = supplyPoint.installationFloorId;
    }
  }

  @bound
  confirmChanges() {
    this.currentStep === "step1" ? this.saveChanges("details") : this.saveChanges("address");
  }

  @action.bound
  @watchBusy
  async saveChanges(changeType: ChangeType) {
    this.errorMessage = undefined;
    this.fileSelectError = undefined;

    const { supplyPoint, partnerId } = this.productDetailContext;

    if (!partnerId || !supplyPoint?.id) {
      return;
    }

    validate(changeType === "details" ? this.supplyPointDetails : this.supplyPointAddress);

    runInAction(() => {
      this.supplyPointDetails.name = this.supplyPointDetails.name || undefined;
      this.supplyPointDetails.flatNumber = this.supplyPointDetails.flatNumber || undefined;
      this.supplyPointDetails.installationFloorId = this.supplyPointDetails.installationFloorId || undefined;
      this.supplyPointAddress.street = this.supplyPointAddress.street || undefined;
    });

    if (this.canConfirm) {
      const response =
        changeType === "details"
          ? await this.supplyPointsRepository.changeSupplyPointDetails(partnerId, supplyPoint.id, this.supplyPointDetails)
          : await this.supplyPointsRepository.changeSupplyPointAddress(partnerId, supplyPoint.id, this.supplyPointAddress);

      if (response.success) {
        this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
        this.requestClose();
      } else {
        runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
      }
    }
  }

  @action.bound
  onDrop(files: File[]) {
    this.supplyPointAddress.file = files;
    this.fileSelectError = undefined;
  }

  @action.bound
  onDropRejected() {
    this.fileSelectError = this.translateGeneral("upload_invalid_files");
  }

  @action.bound
  changeStep(step: StepName) {
    this.currentStep = step;
  }

  get canConfirm() {
    if (this.currentStep === "step1") {
      return !hasVisibleErrors(this.supplyPointDetails);
    } else if (this.currentStep === "step2") {
      return !hasVisibleErrors(this.supplyPointAddress);
    }
    return false;
  }

  get supplyPointAddressText() {
    return this.productDetailContext.supplyPoint?.addressText;
  }

  @computed
  get installationFloors() {
    return this.enumsService.getValues("installationFloors").filter(x => x.active);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`supply_points.detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (productDetailContext: ProductDetailContext) => {
      return new ModalEditSupplyPointViewModel(
        productDetailContext,
        container.get("ILocalizationService"),
        container.get(SupplyPointsRepository),
        container.get("INotificationService"),
        container.get(EnumsService),
        container.get(AddressChangeViewModel.Factory)
      );
    };
  }
}
