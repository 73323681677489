import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, observable, runInAction } from "mobx";
import { IAdminModule } from "viewModels/types";
import SimplePortalSettings from "entities/simplePortalSettings";
import { validate } from "@frui.ts/validation";
import PortalSettingsService from "services/portalSettingsService";

const navigationName = "notice-settings";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class NoticeSettingsPageViewModel extends ScreenBase implements IAdminModule {
  navigationName = navigationName;
  orderIndex = 70;
  menuPart = "content";
  busyWatcher = new BusyWatcher();

  @observable settings: SimplePortalSettings[];

  @observable errorMessage?: string;

  constructor(public localization: ILocalizationService, private portalSettingsService: PortalSettingsService) {
    super();

    this.name = this.translate("title");

    runInAction(() => {
      this.settings = this.portalSettingsService.getValues("simple");
    });
  }

  @action.bound
  @watchBusy
  async confirmRequest(setting: SimplePortalSettings) {
    validate(setting);

    if (setting) {
      await this.portalSettingsService.updatePortalSettings(setting);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.notice_settings.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
