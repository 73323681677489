import { Button, Card, Col, Layout, NotificationBar, Row, AccountStatus, LinkList, LoaderOverlay } from "@emanprague/ppaskit";
import iconArrowRightGray from "@emanprague/ppaskit/dist/assets/icons/Arrow/Right.svg";
import iconRequest from "@emanprague/ppaskit/dist/assets/icons/Comment Fill.svg";
import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import DashboardViewModel from "viewModels/dashboard/dashboardViewModel";
import InvoicesPageViewModel from "viewModels/finance/invoicesPageViewModel";
import AdvancesPageViewModel from "viewModels/finance/advancesPageViewModel";
import { createColumnsContext, DataColumn } from "controls/tableHelper";
import SupplyPointInline from "controls/supplyPointInline";
import { getSupplyPointTypeIcon } from "helpers/supplyPointHelper";
import { getColoredLabelFinance } from "helpers/labelHelper";
import { getIconByStateType } from "helpers/financeHelper";
import { ResponsiveTable } from "@frui.ts/dataviews";
import RequestsOverviewViewModel from "viewModels/requests/overviewViewModel";
import "./style.scss";
import InvoiceListItem from "entities/invoiceListItem";
import AdvanceListItem from "entities/advanceListItem";
import { CrossSellBanner, LargeBanner, SmallBanner } from "controls/banner";
import SapDashboardNotice from "components/SapDashboardNotice";
import { NoticeBar } from "components/NoticeBar";

const columns: DataColumn<AdvanceListItem | InvoiceListItem, DashboardViewModel>[] = [
  {
    property: "supplyPoints",
    titleFactory: ({ tg }) => tg("general.filter_supply_point"),
    valueFormatter: valueProps => {
      const { supplyPoints } = valueProps.item;
      const commodityId = supplyPoints?.[0]?.commodityId ?? -1;

      return (
        <>
          {valueProps.item.supplyPoints && (
            <>
              {valueProps.item.supplyPoints?.length === 1 ? (
                valueProps.item.supplyPoints.map(x => (
                  <SupplyPointInline
                    key={x.id}
                    supplyPoint={x}
                    commodityType={valueProps.context.vm.enumsService.getCommodityType(x.commodityId)}
                    state={valueProps.item.state}
                  />
                ))
              ) : (
                <>
                  <img
                    className="mr-2"
                    width="22"
                    height="21"
                    draggable={false}
                    src={getSupplyPointTypeIcon(
                      valueProps.context.vm.enumsService.getCommodityType(commodityId),
                      valueProps.item.state === "unpaid" ? "red" : "yellowAndLightBlue"
                    )}
                    alt=""
                  />
                  {getColoredLabelFinance(
                    valueProps,
                    valueProps.item.supplyPoints.length.toString() +
                      " " +
                      valueProps.context.tm("supply_point", valueProps.item.supplyPoints.length).toLowerCase()
                  )}
                </>
              )}
            </>
          )}
        </>
      );
    },
  },
  {
    titleFactory: ({ ta }) => ta("attributes", "period"),
    valueFormatter: valueProps =>
      getColoredLabelFinance({ item: valueProps.item, value: valueProps.context.vm.getRecordPeriod(valueProps.item) }),
  },
  {
    titleFactory: ({ vm }) => vm.translateGeneral("type"),
    valueFormatter: valueProps =>
      getColoredLabelFinance({ item: valueProps.item, value: valueProps.context.vm.getRecordType(valueProps.item) }),
  },
  {
    property: "amount",
    titleFactory: ({ ta }) => ta("advance", "amount"),
    valueFormatter: valueProps => (
      <span className="text-nowrap">
        {getColoredLabelFinance(
          valueProps,
          valueProps.context.vm.localization.formatNumber(valueProps.value) +
            " " +
            valueProps.context.vm.translateGeneral("currency")
        )}
      </span>
    ),
  },
  {
    property: "stateText",
    titleFactory: ({ ta }) => ta("advance", "state"),
    valueFormatter: valueProps => (
      <span className="text-nowrap">
        <img className="mr-2" width="22" height="22" draggable={false} src={getIconByStateType(valueProps.item.state)} alt="" />
        {getColoredLabelFinance(valueProps)}
      </span>
    ),
  },
  {
    valueFormatter: ({ item, context }) => {
      const variant: "primary" | "secondary" = item.allowToPay ? "primary" : "secondary";
      const text = item.allowToPay ? "pay_button" : "button_more";

      return (
        <Button
          size="sm"
          variant={variant}
          onClick={
            item instanceof AdvanceListItem ? () => context.vm.openAdvanceDetail(item) : () => context.vm.openInvoiceDetail(item)
          }
          className="btn-responsive-block">
          {context.vm.translateGeneral(text)}
        </Button>
      );
    },
    responsiveTitle: false,
    cellClassName: "cell-right",
  },
];

const dashboardView: ViewComponent<DashboardViewModel> = observer(({ vm }) => (
  <LoaderOverlay loading={vm.busyWatcher.isBusy}>
    {vm.dashboardNotice.active && vm.isDashboardNoticeVisible && (
      <NoticeBar content={vm.dashboardNotice.value} onCancel={vm.handleDashboardpNoticeCancel} />
    )}
    {vm.activePartner?.dashboardNotice && vm.isSapNoticeVisible && (
      <SapDashboardNotice text={vm.activePartner.dashboardNotice} onCancel={vm.handleSapNoticeCancel} />
    )}

    {vm.isPaymentBannerVisible && vm.firstUnpaidPayment && (
      <NotificationBar cancellable onCancel={vm.handlePaymentBannerCancel}>
        <Row className="align-items-center my-md-1">
          <Col xs={12} lg={true} className="mr-lg-auto mb-3 mb-lg-0">
            <span className="font-size-md font-weight-bold">{vm.paymentBannerTitle}</span>
            <br />
            <span className="font-size-sm d-none d-lg-block">{vm.translate("payment_hint")}</span>
          </Col>
          <Col xs={12} lg="auto">
            <Row className="align-items-center">
              <Col xs={12} md="auto" className="ml-auto">
                <span className="font-size-lg font-weight-bold text-nowrap">
                  {vm.localization.formatNumber(vm.firstUnpaidPayment.amount)} {vm.translateGeneral("currency")}
                </span>
              </Col>
              <Col md="auto" className="d-none d-md-block">
                <img alt="qr code" className="d-none d-md-block p-1 bg-white rounded" width="70" height="70" src={vm.qrCodeUrl} />
              </Col>
              <Col md="auto" className="d-none d-md-block text-nowrap font-size-sm">
                <span className="font-weight-bold">{vm.localization.translateAttribute("bank_account", "number")}</span>
                <br />
                <span>{vm.firstUnpaidPayment.accountNumber}</span>
              </Col>
              <Col md="auto" className="d-none d-md-block text-nowrap font-size-sm">
                <span className="font-weight-bold">{vm.localization.translateAttribute("payment", "vs")}</span>
                <br />
                <span>{vm.firstUnpaidPayment.vs}</span>
              </Col>
              <Col xs={12} md="auto" className="mt-2 mt-md-0 text-nowrap mr-3">
                <Button className="mr-1" onClick={vm.activateOnlinePayment}>
                  {vm.translateGeneral("pay_online_button")}
                </Button>
                <Button
                  variant="secondary"
                  className="mr-1 notification__more-button"
                  onClick={vm.handlePaymentBannerDetailClicked}>
                  {vm.translateGeneral("more_button")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </NotificationBar>
    )}

    {vm.isPaymentBannerVisible && vm.firstUnpaidPayment && vm.isRequestBannerVisible && vm.latestRequestBeforeLastLogin && (
      <div className="mb-3" />
    )}

    {vm.isRequestBannerVisible && vm.latestRequestBeforeLastLogin && (
      <NotificationBar cancellable onCancel={vm.handleRequestBannerCancel}>
        <Row className="align-items-center my-md-1" noGutters>
          <Col xs="auto" className="mr-3">
            <img draggable={false} src={iconRequest} alt="" />
          </Col>
          <Col xs="auto" md={true} className="ml-0">
            <span className="font-size-sm">{vm.latestRequestBeforeLastLogin.description}</span>
            <br />
            <span className="d-none d-lg-block font-weight-bold">{vm.translate("request_hint")}</span>
          </Col>
          <Col md={true} className="d-none d-lg-block text-nowrap mr-lg-2">
            <span className="font-size-sm">{vm.translate("request_hint_full_part1")}</span>
            <br />
            <span className="font-size-sm">{vm.translate("request_hint_full_part2")}</span>
          </Col>
          <Col xs={12} md="auto" className="text-nowrap ml-auto mr-3 mt-3 mt-md-0">
            <Button className="mr-1" href={vm.router.getUrl(RequestsOverviewViewModel)}>
              {vm.translate("request_detail")}
            </Button>
          </Col>
        </Row>
      </NotificationBar>
    )}

    <Layout fluid="xl" className="mt-3">
      <Row noGutters className="pb-4">
        <Col xs={12} lg={4} className="mb-3 px-md-2">
          <AccountStatus status={vm.dueItemsSummary === 0} title={vm.accountStatus} className="mx-n3 mx-sm-0 mb-3 h-100">
            <Row>
              <Col>
                {vm.dueItemsSummary === 0 ? (
                  <div className="mb-4 mx-auto">
                    {vm.translateGeneral("balance_widget.ok_detail_part1")}
                    <br />
                    {vm.translateGeneral("balance_widget.ok_detail_part2")}
                  </div>
                ) : (
                  <span className="font-size-sm">
                    {vm.translateGeneral("balance_summary")}:{" "}
                    <strong className="text-nowrap">
                      {vm.localization.formatNumber(vm.balanceSummary)} {vm.translateGeneral("currency")}
                    </strong>{" "}
                    (+&nbsp;{vm.translateGeneral("balance_summary_fees")})
                  </span>
                )}
              </Col>
            </Row>
            {vm.isOneOrMoreDue && (
              <Row className="mt-4 justify-content-center">
                {vm.activePartner && vm.activePartner.depositDueCount > 0 && (
                  <Col xs="auto">
                    <a
                      className="text-indigo text-nowrap font-size-sm"
                      href={vm.router.getUrl(AdvancesPageViewModel, {}, { filter: "unpaid" })}>
                      {vm.translateGeneral("overdue_advances")}
                    </a>
                  </Col>
                )}
                {vm.activePartner && vm.activePartner.invoiceDueCount > 0 && (
                  <Col xs="auto">
                    <a
                      className="text-indigo font-size-sm text-nowrap"
                      href={vm.router.getUrl(InvoicesPageViewModel, {}, { filter: "unpaid" })}>
                      {vm.translateGeneral("overdue_invoices")}
                    </a>
                  </Col>
                )}
              </Row>
            )}
          </AccountStatus>
        </Col>
        <Col xs={12} lg={8} className="mb-3 px-md-2">
          <Card className="h-100">
            <Card.Body>
              <Row className="justify-content-between align-items-center mb-3">
                <Col xs="auto">
                  <h4 className="display-4 mb-0">{vm.localization.translateGeneral("finance.title")}</h4>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    className="font-size-base font-weight-bold link-underline-hover pr-0 d-none d-sm-block"
                    href={vm.router.getUrl(AdvancesPageViewModel)}>
                    {vm.translateGeneral("display_all_button")}
                    <img
                      className="ml-sm-3 opacity-third"
                      width="15"
                      height="12"
                      draggable={false}
                      src={iconArrowRightGray}
                      alt=""
                    />
                  </Button>
                </Col>
              </Row>
              <div className="responsive-table-wrapper">
                <ResponsiveTable
                  className="table no-wrap"
                  items={vm.latestAdvancesAndInvoices}
                  itemKey="id"
                  columns={columns}
                  context={createColumnsContext(vm)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <View vm={vm.activeChild} />
      <Observer>{() => <View vm={vm.supplyPointsViewModel} />}</Observer>

      {vm.crossSellBanner && (
        <CrossSellBanner banner={vm.crossSellBanner} localization={vm.localization} onAddSupplyPointClick={vm.addSupplyPoint} />
      )}

      <Row noGutters className="pb-4">
        <Col xs={12} sm={6} className="mb-3 px-sm-2">
          <LinkList
            title={vm.translate("suggested_links.title")}
            className="mt-3 px-2 px-sm-0"
            links={vm.suggestedLinks.map((item, index) => (
              <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                {item.text}
              </a>
            ))}
          />
        </Col>
        <Col xs={12} sm={6} className="mb-3 px-sm-2">
          <LinkList
            title={vm.translate("help_links.title")}
            className="mt-3 px-2 px-sm-0"
            // eslint-disable-next-line sonarjs/no-identical-functions
            links={vm.questionLinks.map((item, index) => (
              <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                {item.text}
              </a>
            ))}
          />
        </Col>
      </Row>
      {vm.largeBanner && (
        <Row noGutters className="pb-3">
          <Col xs={12} className="mb-3 px-md-2 my-md-5">
            <LargeBanner banner={vm.largeBanner} />
          </Col>
        </Row>
      )}
      {vm.firstSmallBanner && (
        <Row noGutters className="pb-3">
          <Col xs={6} className="mb-3 px-md-2 mb-md-5 mt-md-3">
            {vm.firstSmallBanner && <SmallBanner banner={vm.firstSmallBanner} />}
          </Col>
          <Col xs={6} className="mb-3 px-md-2 mb-md-5 mt-md-3">
            {vm.secondSmallBanner && <SmallBanner banner={vm.secondSmallBanner} />}
          </Col>
        </Row>
      )}
    </Layout>
  </LoaderOverlay>
));

registerView(dashboardView, DashboardViewModel);
