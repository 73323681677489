import { HeaderRow, LoaderOverlay, Card } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import GeneralSettingsPageViewModel from "viewModels/admin/generalSettingsPageViewModel";

const generalSettingsPageView: ViewComponent<GeneralSettingsPageViewModel> = observer(({ vm }) => {
  return (
    <>
      <HeaderRow level={1} title={vm.name} className="mx-1 mx-sm-n3" />
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Card>
          <Card.Body>
            <div className="refresh-enums-card">
              {vm.lastUpdatedAtText()}
              <button className="btn btn-primary" onClick={vm.refreshEnumerations}>
                {vm.translate("refresh_enums")}
              </button>
            </div>
          </Card.Body>
        </Card>
      </LoaderOverlay>
    </>
  );
});

registerView(generalSettingsPageView, GeneralSettingsPageViewModel);
