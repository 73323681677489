import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { ConductorSingleChild, ScreenBase } from "@frui.ts/screens";
import ConfirmRegistrationViewModel from "./profile/confirmRegistrationViewModel";
import ForgottenPasswordViewModel from "./profile/forgottenPasswordViewModel";
import LoginViewModel from "./profile/loginViewModel";
import RegistrationViewModel from "./profile/registrationViewModel";
import ResetPasswordViewModel from "./profile/resetPasswordViewModel";
import { action, runInAction } from "mobx";
import ConfirmUpdateLoginEmail from "entities/confirmUpdateLoginEmail";
import LoginRepository from "repositories/loginRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import { bound } from "@frui.ts/helpers";
import ToastViewModel from "./toastViewModel";
import PartnerConfirmViewModel from "./dashboard/partnerConfirmViewModel";
import SimplePortalSettings from "entities/simplePortalSettings";
import PortalSettingsService from "services/portalSettingsService";

export default class AnonymousRootViewModel extends ConductorSingleChild<ScreenBase> {
  loginNoticeSetting: SimplePortalSettings;
  data: ConfirmUpdateLoginEmail;
  errorMessage?: string;
  constructor(
    public localization: ILocalizationService,
    private login: LoginViewModel,
    private registrationFactory: () => RegistrationViewModel,
    private forgottenPasswordFactory: () => ForgottenPasswordViewModel,
    private confirmRegistrationFactory: () => ConfirmRegistrationViewModel,
    private resetPasswordFactory: () => ResetPasswordViewModel,
    private confirmPartnerFactory: () => PartnerConfirmViewModel,
    private loginRepository: LoginRepository,
    private portalSettingsService: PortalSettingsService,
    private notificationService: INotificationService
  ) {
    super();
    this.data = new ConfirmUpdateLoginEmail();

    runInAction(() => {
      this.loginNoticeSetting = this.portalSettingsService.getSimplePortalSettings("login_notice");
    });
  }

  protected onInitialize() {
    if (!this.activeChild) {
      return this.tryActivateChild(this.login);
    }
  }

  @action navigate(subPath: string | undefined, params: any) {
    if (params?.token && subPath?.startsWith("confirmEmail")) {
      this.data.token = params.token;
      return this.confirmEmailUpdate();
    } else {
      return super.navigate(subPath, params);
    }
  }

  findNavigationChild(navigationName: string) {
    switch (navigationName) {
      case RegistrationViewModel.navigationName:
        return this.createRegistrationVM();
      case ForgottenPasswordViewModel.navigationName:
        return this.createForgottenPasswordVM();
      case ConfirmRegistrationViewModel.navigationName:
        return this.confirmRegistrationFactory();
      case PartnerConfirmViewModel.navigationName:
        return this.confirmPartnerFactory();
      case ResetPasswordViewModel.navigationName:
        return this.resetPasswordFactory();
      default:
        return this.login;
    }
  }

  @action.bound
  async confirmEmailUpdate() {
    const response = await this.loginRepository.updateEmailConfirm(this.data);

    if (response.success) {
      this.notificationService.addNotification(this.translate("email_confirmed"), SeverityLevel.success);
      this.loginRepository.currentUser();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  private createRegistrationVM() {
    const viewModel = this.registrationFactory();
    viewModel.registrationData.email = this.login.credentials.email;
    return viewModel;
  }

  private createForgottenPasswordVM() {
    const viewModel = this.forgottenPasswordFactory();
    viewModel.data.email = this.login.credentials.email;
    return viewModel;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.registration.${key}`);
  }

  get toasts() {
    const results: ToastViewModel[] = [];

    const service = this.notificationService;
    service.notifications.forEach((notification, key) =>
      results.push(new ToastViewModel(key, notification.message, notification.severity, () => service.removeNotification(key)))
    );

    return results;
  }
}
