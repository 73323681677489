import { Button, Card, Col, LoaderOverlay, Row } from "@emanprague/ppaskit";
import iconDownloadBlue from "@emanprague/ppaskit/dist/assets/icons/Download Blue.svg";
import iconIndicatorBlue from "@emanprague/ppaskit/dist/assets/icons/Indicator Blue.svg";
import iconIndicatorGreen from "@emanprague/ppaskit/dist/assets/icons/Indicator Green.svg";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { Observer, observer } from "mobx-react-lite";
import React from "react";
import ContractDetailPageViewModel from "viewModels/products/myProducts/detailPages/contract/contractDetailPageViewModel";

const contractDetailListView: ViewComponent<ContractDetailPageViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  const contractRows1 = (
    <>
      {vm.contract && (
        <>
          <tr>
            <th>{ta("contract", "number")}</th>
            <td className="text-gray">{vm.contract.code}</td>
          </tr>
          <tr>
            <th>{ta("contract", "type")}</th>
            <td className="text-gray">{vm.contract.type}</td>
          </tr>
          <tr>
            <th>{ta("contract", "product")}</th>
            <td className="text-gray">{vm.contract.product}</td>
          </tr>
        </>
      )}
    </>
  );

  const contractRows2 = (
    <>
      {vm.contract && (
        <>
          <tr>
            <th>{ta("contract", "state")}</th>
            <td>
              {vm.contract.active ? (
                <span className="text-success">
                  <img className="pr-2 pb-1" src={iconIndicatorGreen} alt="" />
                  {vm.translateGeneral("active")}
                </span>
              ) : (
                <span className="text-info">
                  <img className="pr-2 pb-1" src={iconIndicatorBlue} alt="" />
                  {vm.translateGeneral("inactive")}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <th>{vm.translate("validity")}</th>
            <td className="text-gray">
              {vm.localization.formatDate(vm.contract.dateFrom)}
              {vm.contract.dateTo && <>{" - " + vm.localization.formatDate(vm.contract.dateTo)}</>}
            </td>
          </tr>
        </>
      )}
    </>
  );

  return (
    <div className="mb-4">
      <>
        <Row className="justify-content-between align-items-center">
          <Col xs={12} sm="auto" className="mb-3">
            <h2 className="display-2 mb-0">{vm.name}</h2>
          </Col>
          <Col xs={12} sm="auto" className="mb-3">
            {vm.isProlongationActive && (
              <Button variant="primary" onClick={vm.prolongContract}>
                {vm.translate("button_prolong_contract")}
              </Button>
            )}
          </Col>
        </Row>
        <LoaderOverlay loading={vm.busyWatcher.isBusy} className="h-100">
          <Card>
            <Card.Body>
              <Row>
                <Col md={6} className="mb-4 mb-sm-0 d-none d-md-inline">
                  <table className="table aligned wider-row">
                    <tbody>{contractRows1}</tbody>
                  </table>
                </Col>
                <Col md={6} className="d-none d-md-inline">
                  <table className="table aligned wider-row">
                    <tbody>{contractRows2}</tbody>
                  </table>
                </Col>
                <Col xs={12} className="d-inline d-md-none">
                  <table className="table aligned wider-row">
                    <tbody>
                      {contractRows1}
                      {contractRows2}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {!!vm.newAppendixes?.length && (
            <>
              <div className="mt-5 mb-4">
                <h1 className="display-3 mb-0">{vm.translate("appendix_offer")}</h1>
              </div>
              <Card>
                <Card.Body>
                  <table className="table responsive-col2row last-column-right font-size-sm">
                    <thead>
                      <tr>
                        <th>{vm.translate("product")}</th>
                        <th>{vm.translate("offer_validity")}</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {vm.newAppendixes.map((appendix, index) => (
                        <tr className="text-gray" key={index}>
                          <td data-title={vm.translate("product")}>{appendix.product || appendix.name}</td>
                          <td data-title={vm.translate("offer_validity")}>
                            {vm.translate("until")} {vm.localization.formatDate(appendix.dateTo)}
                          </td>
                          <td>
                            <Row className="justify-content-end" noGutters>
                              <Col xs={12} sm="auto" className="mb-2 mb-sm-0 mr-sm-2">
                                <Button
                                  variant="secondary"
                                  className="w-100"
                                  onClick={() => vm.activateDocumentsListModal(appendix, false)}>
                                  <img src={iconDownloadBlue} width={16} height={16} alt="" className="mr-1 ml-n1 my-n1" />{" "}
                                  {vm.translate("button_download_documents")}
                                </Button>
                              </Col>
                              <Col xs={12} sm="auto">
                                <Button
                                  variant="success"
                                  className="w-100"
                                  onClick={() => vm.activateDocumentsListModal(appendix, true)}>
                                  {vm.translateGeneral("sign_button")}
                                </Button>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </>
          )}

          {!!vm.appendixes?.length && (
            <>
              <div className="mt-5 mb-4">
                <h1 className="display-3 mb-0">{vm.translate("appendixes")}</h1>
              </div>
              <Card>
                <Card.Body>
                  <table className="table responsive-col2row last-column-right font-size-sm">
                    <thead>
                      <tr>
                        <th>{vm.translate("product")}</th>
                        <th>{vm.translate("validity")}</th>
                        <th>{vm.translate("state")}</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {vm.appendixes.map((appendix, index) => (
                        <tr className="text-gray" key={index}>
                          <td data-title={vm.translate("product")}>{appendix.type}</td>
                          <td data-title={vm.translate("validity")}>
                            {vm.localization.formatDate(appendix.dateFrom)} - {vm.localization.formatDate(appendix.dateTo)}
                          </td>
                          <td data-title={vm.translate("state")}>
                            <span className="pr-2">
                              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="4" cy="4" r="4" fill={appendix.color} />
                              </svg>
                            </span>
                            <span style={{ color: appendix.color }}>{appendix.state}</span>
                          </td>
                          <td>
                            {appendix.attachments.length > 0 && (
                              <Row className="justify-content-end pb-2 pb-md-0" noGutters>
                                <Col xs={12} md="auto">
                                  <Button
                                    variant="secondary"
                                    className="w-100"
                                    onClick={() => vm.activateDocumentsListModal(appendix, false)}>
                                    <img src={iconDownloadBlue} width={16} height={16} alt="" className="mr-1 ml-n1 my-n1" />{" "}
                                    {vm.translateGeneral("download_button")}
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </>
          )}
        </LoaderOverlay>
      </>
      <Observer>{() => <View vm={vm.activeChild} />}</Observer>
    </div>
  );
});

registerView(contractDetailListView, ContractDetailPageViewModel);
