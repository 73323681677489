import { HeaderRow, InputSearch, LoaderOverlay } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ScreenSharePageViewModel from "viewModels/admin/screenSharePageViewModel";
import { Button, Card, Col, Row } from "react-bootstrap";
import { createColumnsContext, DataColumn } from "controls/tableHelper";
import { ResponsiveTable } from "@frui.ts/dataviews";
import EmptyDataMessage from "components/EmptyDataMessage";
import { addressToHuman, partnerToFullName } from "helpers/utils";
import Tooltip from "@emanprague/basekit/dist/Tooltip";
import PartnerImpersonationListItem from "entities/partnerImpersonationListItem";

const columns: DataColumn<PartnerImpersonationListItem, ScreenSharePageViewModel>[] = [
  {
    titleFactory: ({ vm }) => vm.localization.translateAttribute("partner", "first_name"),
    valueFormatter: ({ item, context }) => <>{partnerToFullName(context.vm.enumsService, item)}</>,
  },
  {
    property: "externalId",
    titleFactory: ({ vm }) => vm.localization.translateAttribute("partner", "external_id"),
  },
  {
    property: "email",
    titleFactory: ({ vm }) => (
      <Tooltip placement="top" id="email-tooltip" tooltip={vm.localization.translateGeneral("admin.screen_share.email_tooltip")}>
        <span>{vm.localization.translateAttribute("partner", "email")}</span>
      </Tooltip>
    ),
  },
  {
    property: "permanentAddress",
    valueFormatter: ({ value }) => <>{addressToHuman(value)}</>,
    titleFactory: ({ vm }) => vm.localization.translateAttribute("partner", "address"),
  },
  {
    valueFormatter: ({ context, item }) => (
      <Button
        size="sm"
        className="btn-responsive-block"
        onClick={() => context.vm.selectCustomer(item.id, item.userId)}
        disabled={item.locked}>
        {item.locked ? (
          <Tooltip placement="top" id="locked-tooltip" tooltip={context.vm.translate("locked_tooltip")}>
            <span>{context.vm.translateGeneral("locked")}</span>
          </Tooltip>
        ) : (
          <span>{context.vm.translateGeneral("select_button")}</span>
        )}
      </Button>
    ),
    responsiveTitle: false,
    cellClassName: "cell-right",
  },
];

const screenSharePageView: ViewComponent<ScreenSharePageViewModel> = observer(({ vm }) => {
  const tg = vm.localization.translateGeneral;
  return (
    <>
      <HeaderRow level={2} title={vm.translate("header")} className="mx-1 mx-sm-n3" />
      <Row className="mt-4">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <LoaderOverlay loading={vm.busyWatcher.isBusy}>
                <Row className="mb-3 justify-content-between align-items-center">
                  <Col xs={12} md={6} lg="auto" className="py-2 mb-2 mb-md-0">
                    <HeaderRow title={vm.translate("search_label")} level={4} />
                  </Col>
                  <Col xs={12} md={5} className="py-2">
                    <InputSearch
                      type="text"
                      placeholder={vm.translate("search_placeholder")}
                      value={vm.filter.search}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => vm.setSearchValue(e.target.value)}
                    />
                  </Col>
                </Row>

                <div className="responsive-table-wrapper">
                  <ResponsiveTable
                    className="table no-wrap"
                    items={vm.items}
                    itemKey="key"
                    columns={columns}
                    context={createColumnsContext(vm)}
                  />
                </div>
                {!vm.items?.length && <EmptyDataMessage translateGeneral={tg} />}

                {vm.canLoadData && (
                  <div className="load-more-button">
                    <Button onClick={vm.loadData} variant="secondary">
                      {tg("general.button_load_more")}
                    </Button>
                  </div>
                )}
              </LoaderOverlay>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
});

registerView(screenSharePageView, ScreenSharePageViewModel);
