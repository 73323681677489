import type { IAdminModule } from "viewModels/types";
import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable, runInAction } from "mobx";
import GeneralSettingsRepository from "repositories/generalSettingsRepository";

const navigationName = "general-settings";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class EnumerationsPageViewModel extends ScreenBase implements IAdminModule {
  navigationName = navigationName;
  orderIndex = 300;
  menuPart = "admin";
  busyWatcher = new BusyWatcher();

  @observable last_enum_update?: Date;

  constructor(public localization: ILocalizationService, private generalSettingsRepository: GeneralSettingsRepository) {
    super();

    this.name = this.translate("title");
  }

  protected onInitialize(): Promise<any> | void {
    return this.loadData();
  }

  @bound
  @watchBusy
  async refreshEnumerations() {
    await this.generalSettingsRepository.refreshEnums();
    await this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    const response = await this.generalSettingsRepository.generalInfo();
    if (response.success) {
      runInAction(() => {
        this.last_enum_update = response.payload.last_enum_update;
      });
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.general_settings.${key}`);
  }

  @bound lastUpdatedAtText() {
    const date = this.localization.formatDateTime(this.last_enum_update);

    return this.localization.translateGeneral(`admin.general_settings.last_updated_at`, {
      date: date || this.translate("unknown"),
    });
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
