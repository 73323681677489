import { Expose, Transform, Type } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import AllowedFrequencyChange from "./allowedFrequencyChange";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Contract {
  id!: number;

  @observable
  code!: string;

  @observable
  active?: boolean;

  @observable
  type!: string;

  @observable
  product!: string;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "date_from" })
  dateFrom!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "date_to" })
  dateTo?: Date;

  /**
   * Flag pro změnu záloh
   */
  @observable
  @Expose({ name: "allow_to_change_advance_frequency" })
  allowToChangeAdvanceFrequency?: boolean;

  /**
   * Current advance frequency settings
   */
  @observable
  @Expose({ name: "advance_frequency_id" })
  advanceFrequencyId?: number;

  /**
   * Current advance amount
   */
  @observable
  @Expose({ name: "advance_amount" })
  advanceAmount?: number;

  @observable
  @Type(() => AllowedFrequencyChange)
  @Expose({ name: "allowed_frequency_changes" })
  allowedFrequencyChanges?: AllowedFrequencyChange[];

  static ValidationRules = {
    id: { required: true, number: true },
    code: { required: true },
    type: { required: true },
    product: { required: true },
    dateFrom: { required: true, date: true },
    dateTo: { date: true },
    advanceFrequencyId: { number: true },
    advanceAmount: { number: true },
  };
}
